<template>
  <div class="line">
    <span class="tiitle">{{ lists.name }}</span>
    <a-checkbox-group
      class="spans"
      v-model="checkedList"
      :options="lists.data"
      @change="handleChange"
    />
  </div>
</template>
<script>
export default {
  props: {
    lists: {
      type: Object,
      default: () => {
        return {
          name: "",
          data: [],
        };
      },
    },
  },
  data() {
    return {
      checkedList: [],
    };
  },

  mounted() {
    console.log(this.lists);
  },

  methods: {
    // handeleClick(id) {
    //   this.$emit("change", id);
    // },
    // 处理选中
    handleChange(arr) {
      let areaStr = arr.toString();
      this.$emit("change", areaStr);
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  color: #333;
  padding-bottom: 15px;
  padding-top: 15px;
  display: flex;
  .tiitle {
    margin-right: 0px;
    width: 120px;
    display: inline-block;
  }
  .spans {
    margin-right: 20px;
    width: 1200px;
    margin-left: 15px;
    cursor: pointer;
  }
  .actives {
    color: #fe8432;
  }
}
</style>
