<template>
  <div class="boxs">
    <div>
      <span class="primColocr">{{ datas.name }}</span>
      <span class="ant-table-column-sorter icons" v-if="datas.type === 'sort'">
        <div
          class="ant-table-column-sorter-inner ant-table-column-sorter-inner-full"
        >
          <a-icon
            class="icon"
            :class="datas.active === 'up' ? 'active' : ''"
            type="caret-up"
            @click="actives('up')"
          />
          <a-icon
            class="icon"
            :class="datas.active === 'down' ? 'active' : ''"
            type="caret-down"
            @click="actives('down')"
          />
        </div>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          id: null,
          name: "",
          type: "normal",
          active: "",
        };
      },
    },
  },
  data() {
    return {
      // active: "",
    };
  },

  mounted() {},

  methods: {
    actives(type) {
      // this.active = type;
      this.datas.active = type;
      let name;
      // let name = this.datas.name === "价格" ? "price" : "order";
      if (this.datas.name === "价格") {
        name = "price";
      } else if (this.datas.name === "定制起订量") {
        name = "order";
      } else if (this.datas.name === "成交量") {
        name = "make";
      } else if (this.datas.name === "询价量") {
        name = "inquiry";
      }else if(this.datas.name==='库存起批量'){
        name='minInventory'
      }else if(this.datas.name==='现有库存'){
        name='inventoryNumber'
      }
      this.$emit("change", { name: name, type: type, id: this.datas.id });
    },
  },
};
</script>
<style lang="scss" scoped>
.boxs {
  color: #333;
  margin-right: 30px;
  & > div {
    display: flex;
  }
}
.icons {
  display: table-cell;
  vertical-align: middle;
  margin-left: 5px;
}
.icon {
  display: block;
  font-size: 12px;
  height: 8px;
  line-height: 8px;
  color: #bfbfbf;
  cursor: pointer;
}
.active {
  color: #ff4600;
}
</style>
