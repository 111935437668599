<template>
  <div class="line">
    <span class="tiitle">{{ lists.name }}</span>
    <span
      class="spans"
      v-for="(item, index) in lists.data"
      :class="active == item.id ? 'actives' : ''"
      :key="index"
      @click="handeleClick(item.id)"
      >{{ item.name }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    lists: {
      type: Object,
      default: () => {
        return {
          name: "",
          data: [],
        };
      },
    },
  },
  data() {
    return {
      active: "",
    };
  },

  mounted() {},

  methods: {
    handeleClick(id) {
      this.active = id;
      this.$emit("change", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  color: #333;
  padding-bottom: 15px;
  padding-top: 15px;
  .tiitle {
    margin-right: 0px;
    width: 120px;
    display: inline-block;
  }
  .spans {
    margin-right: 20px;
    cursor: pointer;
  }
  .actives {
    color: #fe8432;
  }
}
</style>
