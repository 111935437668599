import axios from "@/config/http";
export const CategoryAPI = {
  getCategory() {
    return axios.get(`/mall/v1/index/goodsCategory`);
  },

  searchGoods(params) {
    return axios.get(`/mall/v1/index/searchGoods`, { params });
  },

  GoodDetail(params) {
    return axios.get(`/mall/v1/index/goodsDetail/`, { params });
  },

  getKeyWord() {
    return axios.get(`/mall/v1/index/keywords`);
  },

  // 获取可售区域
  areaData(name) {
    return axios.get(`/mall/v1/order/getDict?dictName=${name}`);
  },
};
