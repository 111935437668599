<template>
  <div>
    <!-- <Breadcrumb /> -->
    <div class="bg">
      <div class="home-container">
        <div class="home-content textleft">
          <a-radio-group
            class="switchbox"
            v-model="type"
            button-style="solid"
            @change="changeType"
          >
            <a-radio-button value="1"> 全部选品 </a-radio-button>
            <a-radio-button value="6"> 定制 </a-radio-button>
            <a-radio-button value="2"> 爆款 </a-radio-button>
            <a-radio-button value="3"> 新品 </a-radio-button>
            <a-radio-button value="4"> 私域礼品 </a-radio-button>
            <a-radio-button value="5"> 库存处理 </a-radio-button>
          </a-radio-group>
          <!-- s筛选条件 -->
          <Sort :lists="price" @change="changePrice" />
          <Sort
            :lists="count"
            @change="changeCount"
            v-if="type !== '5' && type !== '1'"
          />
          <Sort :lists="batchCount" @change="changeBatchCount" v-if="type === '5'" />
          <Select :lists="authentication" @change="changeAuthentication" />
          <Select :lists="area" @change="changeArea" />
        </div>
      </div>
      <div class="home-container mat15">
        <div class="home-content">
          <div class="searchHeader">
            <SortHeader
              v-for="(item, index) in HeaderSearch"
              :key="index"
              :datas="item"
              @change="changeSort"
            />
          </div>
          <Loading style="height: 300px" v-if="loading" />
          <!-- 私域礼品 -->
          <div v-if="!loading" class="panel title-panel private-gift-container">
            <div class="private-gift-list" v-if="ScopeGood.length > 0">
              <GoodCard
                v-for="(item, index) in ScopeGood"
                class="gift-detail"
                :key="'ScopeGood' + index"
                :item="item"
                :type="type"
                @click.native="JumpDetail(item.goodsId)"
              />
            </div>
            <div class="emptybox" v-else>
              <a-empty>
                <span slot="description"> 暂无数据 </span>
              </a-empty>
            </div>
          </div>
          <!-- 分页 -->
          <a-pagination
            v-if="!loading"
            class="pagination"
            :current="pagination.current"
            :total="pagination.total"
            :defaultPageSize="pagination.size"
            @change="changes"
            @showSizeChange="onsize"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sort from "./../components/sort"
import Select from "./../components/select.vue"
import SortHeader from "./../components/sortHeader"
import GoodCard from "@/components/GoodCard"
import Loading from "@/components/loading"
// import Breadcrumb from "../components/breadcrumb.vue";
import { mapState, mapMutations } from "vuex"
// const { mapState } = createNamespacedHelpers("Header");
import { CategoryAPI } from "@/api/Category.js"
import Routerpush from "@/mixins/Routerpush"
export default {
  mixins: [Routerpush],
  data () {
    return {
      type: "1",
      price: {
        name: "价格区间",
        data: [
          {
            id: "",
            name: "全部",
          },
          {
            id: "0_49",
            name: "小于50",
          },
          {
            id: "50_199",
            name: "50-200",
          },
          {
            id: "200_499",
            name: "200-500",
          },
          {
            id: "500_999",
            name: "500-1000",
          },
          {
            id: "1000_1999",
            name: "1000-2000",
          },
          {
            id: "2000_",
            name: "2000以上",
          },
        ],
      },
      count: {
        name: "起订量区间",
        data: [
          {
            id: "",
            name: "全部",
          },
          {
            id: "0_99",
            name: "小于100",
          },
          {
            id: "0_499",
            name: "小于500",
          },
          {
            id: "0_999",
            name: "小于1000",
          },
          {
            id: "0_1499",
            name: "小于1500",
          },
          {
            id: "0_1999",
            name: "小于2000",
          },
          {
            id: "2000_",
            name: "2000以上",
          },
        ],
      },
      batchCount: {
        name: "起批量区间",
        data: [
          {
            id: "",
            name: "全部",
          },
          {
            id: "0_99",
            name: "小于100",
          },
          {
            id: "0_499",
            name: "小于500",
          },
          {
            id: "0_999",
            name: "小于1000",
          },
          {
            id: "0_1499",
            name: "小于1500",
          },
          {
            id: "0_1999",
            name: "小于2000",
          },
          {
            id: "2000_",
            name: "2000以上",
          },
        ],
      },
      area: {
        name: "可售区域",
        data: [],
      },
      authentication: {
        name: "商品认证",
        data: [
          "CCC",
          "ISO",
          "NCC",
          "UL",
          "ETL",
          "GS",
          "FCC",
          "CE",
          "ROHS",
          "HACCP",
          "CB",
          "BSI",
          "PSE",
          "SOSA",
          "CU-TR",
          "SAA",
          "KC",
          "SNI",
          "ICASA",
          "CSA",
          "NF",
          "定制认证",
        ],
      },
      HeaderSearch: [
        {
          id: 1,
          name: "默认排序",
          type: "normal",
          active: "",
        },
        {
          id: 2,
          name: "价格",
          type: "sort",
          active: "",
        },
        {
          id: 3,
          name: "定制起订量",
          type: "sort",
          active: "",
        },
        {
          id: 4,
          name: "成交量",
          type: "sort",
          active: "",
        },
        {
          id: 5,
          name: "询价量",
          type: "sort",
          active: "",
        },
      ],
      ScopeGood: [],
      // 分页
      current: "",
      total: 100,
      pagination: {
        current: 1,
        size: 20,
        total: 100,
      },
      search: {
        goodsCatogory: "",
        goodsColumn: "1",
        keyWords: "",
        minOrder: "",
        onPrice: "",
        pageNum: 1,
        pageSize: 20,
        sortField: "",
        sortType: "",
        availableArea: "", // 可售区域
        productQualification: "", // 商品认证
      },
      loading: true,
    }
  },
  components: {
    Sort,
    SortHeader,
    GoodCard,
    Loading,
    Select,
    // Breadcrumb,
  },

  computed: {
    ...mapState("Header", ["Search", "Part", "Searchtime"]),
    ...mapState("Public", ["Token", "firstStatus", "Num"]),
  },

  watch: {
    Searchtime: {
      deep: true,
      // immediate: true,
      handler () {
        this.search.keyWords = this.Search
        this.search.goodsCatogory = this.Part
        this.pagination.current = 1
        this.search.pageNum = 1
        this.SearchFn()
      },
    },
    type (newValue) {
      if (newValue === '5') {
        this.HeaderSearch=[
        {
          id: 1,
          name: "默认排序",
          type: "normal",
          active: "",
        },
        {
          id: 2,
          name: "价格",
          type: "sort",
          active: "",
        },
        {
          id: 5,
          name: "询价量",
          type: "sort",
          active: "",
        },
        {
            id: 6,
            name: "库存起批量",
            type: "sort",
            active: "",
          },
          {
            id: 7,
            name: "现有库存",
            type: "sort",
            active: "",
          }
        ]
      } else if (newValue === '1') {
        this.HeaderSearch = [{
          id: 1,
          name: "默认排序",
          type: "normal",
          active: "",
        },
        {
          id: 2,
          name: "价格",
          type: "sort",
          active: "",
        },
        {
          id: 5,
          name: "询价量",
          type: "sort",
          active: "",
        }]
      }else {
        this.HeaderSearch = [
        {
          id: 1,
          name: "默认排序",
          type: "normal",
          active: "",
        },
        {
          id: 2,
          name: "价格",
          type: "sort",
          active: "",
        },
        {
          id: 3,
          name: "定制起订量",
          type: "sort",
          active: "",
        },
        {
          id: 4,
          name: "成交量",
          type: "sort",
          active: "",
        },
        {
          id: 5,
          name: "询价量",
          type: "sort",
          active: "",
        },
      ]
      }
    },
  },

  created () {
    this.getAreaList()
    const type = this.$route.query.type
    this.search.goodsCatogory = this.Part
    this.search.keyWords = this.Search
    this.search.goodsColumn = type
    if (type) {
      this.type = type
    }
    this.SearchFn()
  },

  destroyed () {
    //搜索
    console.log("页面离开")
    this.change("")
  },

  methods: {
    ...mapMutations("Header", ["change"]),
    changes (page) {
      this.pagination.current = page
      this.search.pageNum = page
      // this.search.pageSize = page;
      this.SearchFn()
    },
    onsize () { },

    //价格区间改变
    changePrice (e) {
      this.search.onPrice = e
      this.pagination.current = 1
      this.search.pageNum = 1
      this.SearchFn()
    },

    //起订量区间改变
    changeCount (e) {
      this.pagination.current = 1
      this.search.pageNum = 1
      this.search.minOrder = e
      this.SearchFn()
    },
        //起订量区间改变
    changeBatchCount (e) {
      this.pagination.current = 1
      this.search.pageNum = 1
      this.search.minInventory = e
      this.SearchFn()
    },

    // 可售区域改变
    changeArea (e) {
      this.pagination.current = 1
      this.search.pageNum = 1
      this.search.availableArea = e
      this.SearchFn()
    },

    // 改变商品认证
    changeAuthentication (e) {
      console.log(e)
      this.pagination.current = 1
      this.search.pageNum = 1
      this.search.productQualification = e
      this.SearchFn()
    },

    changeType (value) {
      console.log("切换", value.target.value)
      this.pagination.current = 1
      this.search.pageNum = 1
      this.search.goodsColumn = this.type
      this.SearchFn()
    },
    // 筛选部分--价格--定制起订量--成交量--询价量--库存起批量--现有库存
    changeSort (e) {
      console.log(e)
      this.HeaderSearch.map((item) => {
        if (item.id !== e.id) {
          item.active = ""
        }
      })
      if (e.name === "price") {
        this.search.sortField = "onPrice"
      } else if (e.name === "order") {
        this.search.sortField = "minOrder"
      } else if (e.name === "make") {
        this.search.sortField = "makeTotalNumber"
      } else if (e.name === "inquiry") {
        this.search.sortField = "inquiryTotalNumber"
      } else if (e.name === "minInventory") {
        this.search.sortField = "minInventory"
      } else if (e.name === "inventoryNumber") {
        this.search.sortField = "inventoryNumber"
      }
      this.search.sortType = e.type === "down" ? "desc" : "asc"
      this.pagination.current = 1
      this.search.pageNum = 1
      this.SearchFn()
    },

    SearchFn () {
      this.loading = true
      CategoryAPI.searchGoods(this.search).then((res) => {
        if (res.success) {
          console.log(res)
          this.loading = false
          this.pagination.total = res.data ? res.data.total : 0
          this.ScopeGood = res.data
            ? res.data.records.map((i) => {
              if (this.search.goodsColumn === 1) {
                i.title = "全部商品"
              } else if (this.search.goodsColumn === 2) {
                i.title = "爆款"
              } else if (this.search.goodsColumn === 3) {
                i.title = "新品"
              } else if (this.search.goodsColumn === 4) {
                i.title = "私域礼品"
              } else if (this.search.goodsColumn === 5) {
                i.title = "库存处理"
              } else if (this.search.goodsColumn === 6) {
                i.title = "定制"
              }
              i.inventoryTags =
                i.inventoryTags === null ? [] : i.inventoryTags.split("/")
              i.specialTag =
                i.specialTag === null ? [] : i.specialTag.split("/")
              return i
            })
            : []
        }
      })
    },

    //点击提示
    tips () {
      console.log(this.Token)
      return new Promise((resolve) => {
        if (this.Token === "") {
          let that = this
          this.$confirm({
            title: "提示",
            content: "为了便于您了解选品详情，请登录后进行查看。",
            okText: "去登录",
            cancelText: "取 消",
            onOk () {
              that.loginRoute()
            },
            onCancel () {
              // that.setStatus(false);
            },
          })
        } else {
          resolve()
        }
      })
    },

    checkStatus (id) {
      // console.log(id, "打来新页签111")
      let url = this.$router.resolve({
        path: `detail?goodsId=${id}`,
        query:{
          name:this.type
        }
      })
      window.open(url.href) //注意，需要加href
    },

    //查看详情
    SeeDetail (id) {
      this.checkStatus(id)
    },

    //查看详情
    JumpDetail (id) {
      this.tips().then(() => {
        this.SeeDetail(id)
      })
    },

    // 获取可售地区
    getAreaList () {
      CategoryAPI.areaData("可售区域").then((res) => {
        console.log(res.data)
        let arr = []
        res.data.forEach((item) => {
          arr.push(item.dictValue)
        })
        console.log(arr)
        this.area.data = arr
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  background: #f2f2f2;
  margin-top: 15px;
}
.home-container {
  width: 100%;
  position: relative;
  background: #fff;
  padding: 15px 0px;
  .home-content {
    width: 1200px;
    margin: auto;
  }
  .switchbox {
    margin-bottom: 15px;
  }
}
.searchHeader {
  padding: 15px 0px;
  display: flex;
}

.private-gift-container {
  .private-gift-card-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 33.3%;
    justify-content: space-between;
    align-items: flex;
  }
  .private-gift-list {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .card {
      margin-bottom: 20px;
      margin-right: 10px;
      &:nth-child(5n) {
        margin-right: 0px;
      }
    }
  }
}
.emptybox {
  height: 300px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.pagination {
  text-align: right;
  margin-top: 20px;
}
.pagination a {
  display: inline;
}
</style>
